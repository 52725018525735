html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}
